import { groupBy, sortBy } from "lodash";

export const votesByStreet = (votes) => {
  const details = groupBy(votes, "name");

  const streets = sortBy(
    Object.entries(details).map(([key, value]) => {
      return {
        name: key,
        total: value.reduce((acc, item) => acc + item.votes, 0),
      };
    }),
    ({ name }) => votes.findIndex((item) => item.name === name)
  );

  return { details, streets };
};
