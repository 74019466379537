import { useMemo, useState } from "react";
import { Chart } from "react-google-charts";
import Select from "react-select";
import cn from "classnames";
import votesData from "./data/votes.json";
import { votesByStreet } from "./mapper/votesByStreet";
import { buildStreetOptions } from "./mapper/buildStreetOptions";
import logo from "./logo.jpg";
import "./App.scss";

const App = () => {
  const [street, setStreet] = useState("");
  const { streets, details } = useMemo(() => votesByStreet(votesData), []);
  const streetOptions = useMemo(() => buildStreetOptions(streets), [streets]);

  const streetDetails = details[street?.name];
  const chartData = streetDetails?.map(({ variant, votes }) => [
    variant,
    votes,
    votes,
  ]);

  const isEmpty = !street?.name;
  const isMobile = window.innerWidth < 700;

  const options = {
    legend: "none",
    fontSize: isMobile ? 12 : 14,
    width: "100%",
    height: chartData?.length * 60,
    chartArea: {
      top: 0,
      bottom: 50,
      right: 20,
      left: isMobile ? 160 : "280",
    },
    vAxis: {
      textPosition: "out",
    },
  };

  return (
    <div
      className={cn("app", {
        "is-empty": isEmpty,
      })}
    >
      <h1 className="title">
        Дерусифікація <nobr>в Київ Цифровий</nobr>
      </h1>

      {isEmpty && (
        <div className="info">
          Оберіть вулицю, щоб подивитись результати голосування
        </div>
      )}

      <div className="select">
        <Select
          options={streetOptions}
          placeholder="Пошук вулиці"
          onChange={({ value }) => setStreet(value)}
        />
      </div>

      {chartData && (
        <div className="chart">
          <Chart
            key={street?.name}
            chartType="BarChart"
            data={[
              ["Варіант", "Голосів", { role: "annotation" }],
              ...chartData,
            ]}
            options={options}
          />
        </div>
      )}
      <a
        href="https://kyiv.digital/"
        title="Київ Цифровий"
        className="logo-link"
        target="_blank"
        rel="noreferrer"
      >
        <img className="logo" src={logo} alt="Київ Цифровий" />{" "}
      </a>
    </div>
  );
};

export default App;
